.dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .dashboardContent {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
  }
  
  .quickLinks {
    grid-column: 1 / -1;
  }
  
  .quickLinks ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .quickLinks li {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .quickLinks a {
    text-decoration: none;
    color: #333;
  }
  
  .upcomingBookings,
  .recentActivity {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }