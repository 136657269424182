.profile {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .profileInfo {
    margin-bottom: 20px;
  }
  
  .profileInfo p {
    margin-bottom: 10px;
  }
  
  .editButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .editButton:hover {
    background-color: #0056b3;
  }