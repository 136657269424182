.signUp {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signUp h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.formGroup input[type="text"],
.formGroup input[type="email"],
.formGroup input[type="password"],
.formGroup input[type="tel"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
}

.checkboxGroup label {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.checkboxGroup input[type="checkbox"] {
  margin-right: 10px;
}

.submitButton {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #45a049;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: #ff0000;
  margin-bottom: 15px;
}

/* Style for hCaptcha */
.hcaptcha {
  margin-bottom: 15px;
}