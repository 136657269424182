.checkEmail {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .checkEmail h2 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .checkEmail p {
    color: #666;
    margin-bottom: 1rem;
  }
  
  .link {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .link:hover {
    background-color: #0056b3;
  }