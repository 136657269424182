.footer {
    flex-shrink: 0;
    background-color: #333;
    color: white;
    padding: 2rem 0;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 2rem;
  }
  
  .column {
    flex: 1;
    min-width: 200px;
    margin-bottom: 1rem;
  }
  
  .column h3, .column h4 {
    margin-bottom: 1rem;
  }
  
  .column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .column ul li {
    margin-bottom: 0.5rem;
  }
  
  .column a {
    color: #ccc;
    text-decoration: none;
  }
  
  .column a:hover {
    color: white;
  }
  
  .bottom {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #555;
  }