.becomeGroomerContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .infoSection, .requirementsSection, .ctaSection {
    margin-bottom: 30px;
  }
  
  .infoSection h2, .requirementsSection h2, .ctaSection h2 {
    color: #444;
    margin-bottom: 15px;
  }
  
  .infoSection ul, .requirementsSection ul {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .infoSection li, .requirementsSection li {
    margin-bottom: 10px;
    position: relative;
  }
  
  .infoSection li:before, .requirementsSection li:before {
    content: "✓";
    color: #4CAF50;
    position: absolute;
    left: -20px;
  }
  
  .ctaSection {
    text-align: center;
  }
  
  .signupButton {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 15px;
    transition: background-color 0.3s;
  }
  
  .signupButton:hover {
    background-color: #45a049;
  }