.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1;
}

@media (max-width: 768px) {
    main {
        padding: 0 1rem;
    }
}

.header {
    text-align: center;
    margin-bottom: 30px;
}

.header h1 {
    color: #4a4a4a;
}

.content {
    display: flex;
    gap: 30px;
}

.formSection {
    flex: 1;
}

.listSection {
    flex: 2;
}

.appWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.mainContent {
    flex: 1 0 auto;
}