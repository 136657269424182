.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo a {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4CAF50;  /* Changed to green */
    text-decoration: none;
}

.nav {
    display: flex;
    align-items: center;
}

.navList {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navList li {
    margin-left: 1.5rem;
}

.navList a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.navList a:hover {
    color: #4CAF50;  /* Changed to green */
}

.authButton {
    background-color: #4CAF50;  /* Changed to green */
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.authButton:hover {
    background-color: #45a049;  /* Darker green for hover */
}

.centerLinks {
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav {
        margin-top: 1rem;
        width: 100%;
    }

    .navList {
        flex-direction: column;
        width: 100%;
    }

    .navList li {
        margin-left: 0;
        margin-bottom: 0.5rem;
    }

    .authButton {
        width: 100%;
    }

    .centerLinks {
        margin-left: 0;
        margin-right: 0;
    }
}