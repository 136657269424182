.profileContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.avatarContainer {
  margin-right: 30px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.headerInfo h1 {
  margin: 0 0 10px 0;
  font-size: 28px;
}

.location {
  color: #666;
  margin-bottom: 15px;
}

.editButton {
  background-color: #1dbf73;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.mainContent {
  display: flex;
  gap: 30px;
}

.leftColumn {
  flex: 2;
}

.rightColumn {
  flex: 1;
}

.aboutSection, .servicesSection, .statsSection, .detailsSection, .contactSection {
  background-color: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.aboutSection h2, .servicesSection h2 {
  margin-top: 0;
  color: #404145;
}

.servicesSection ul {
  list-style-type: none;
  padding: 0;
}

.servicesSection li {
  margin-bottom: 10px;
}

.statsSection {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.stat {
  display: flex;
  flex-direction: column;
}

.statValue {
  font-size: 24px;
  font-weight: bold;
  color: #1dbf73;
}

.statLabel {
  color: #666;
}

.detailsSection h3, .contactSection h3 {
  margin-top: 0;
  color: #404145;
}

.loading, .error, .noProfile {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: red;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }

  .avatarContainer {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .mainContent {
    flex-direction: column;
  }
}